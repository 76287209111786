import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import WizardStep from '@oup/shared-front-end/src/components/Wizard/WizardStep';

import { closeForm } from '../../../redux/reducers/placementTestSessionCreate';
import { closePlacementTestOnboardingWizard } from '../../../redux/actions/hubUi';
import { clearWizardState } from '../../../redux/actions/placementTestOnboardingWizard';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { HubIllustrationAltText, HubIllustrationConstants } from '../../../globals/hubConstants';

function PlacementTestSummary({
  placementTestSessionNameValue,
  localizedContent: { placementTests: placementTestsContent }
}) {
  const { isLoading, setIsPrimaryButtonDisabled } = useWizard();

  setIsPrimaryButtonDisabled(isLoading);

  return (
    <div data-testid="ONBOARDING_WIZARD_CREATE_A_PLACEMENT_TEST_SESSION_SUMMARY_CONTAINER">
      <WizardStep
        titleText={`${placementTestSessionNameValue ||
          placementTestsContent.placement_test_notification_page_session_name_fallback} ${
          placementTestsContent.placement_test_summary_page_title
        }`}
        subtitleText={
          <>
            {placementTestsContent.placement_test_summary_page_subtitle}{' '}
            <strong>{placementTestsContent.placement_test_summary_page_subtitle_manage_session}</strong>
          </>
        }
        image={HubIllustrationConstants.SUCCESS}
        imageAlt={HubIllustrationAltText.SUCCESS}
      />
    </div>
  );
}

PlacementTestSummary.propTypes = {
  placementTestSessionNameValue: PropTypes.string,
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  withLocalizedContent('placementTests'),
  connect(
    ({ placementTestSessionCreate }) => ({
      placementTestSessionNameValue: placementTestSessionCreate.placementTestSessionNameValue
    }),
    {
      clearWizardStateAction: clearWizardState,
      clearPlacementTestSessionCreateStateAction: closeForm,
      closeWizardAction: closePlacementTestOnboardingWizard
    }
  )
)(PlacementTestSummary);
