/** Registration reducer
 *  This reducer contains state related to the registration process. This
 *  includes the registration page and the email confirmation page shown
 *  on first login.
 */

// FULL REGISTRATION: http://i3bnio.axshare.com/#g=1&p=3_1_1_2_register
// PARTIAL REGISTRATION: http://i3bnio.axshare.com/#g=1&p=3_1_2_1_partial_registration

// This reducer handles both FULL and PARTIAL registration:
// FULL registration:
//   http://localhost:7700/register
// PARTIAL registration:
//   There are 3 types of partial registration:
//   1. Finalise via TOKEN received in email: http://localhost:7700/register/xxxx (Similar to full registration form)
//   2. Finalise existing user: http://localhost:7700/register/finish (To confirm T&Cs & Privacy)
//   3. Finalise legacy user: http://localhost:7700/register/finish (To confirm Email & T&Cs & Privacy)
//   Note: In DEV you can force the two types of "finish" form using: http://localhost:7700/register/LEGACY or /EXISTING
//   The partial registration forms fetch details from lambdas before displaying form.
//   At time of writing, the following returned useful test data:
//   - http://localhost:7700/register/twelve --> Valid token and valid user
//   - http://localhost:7700/register/nouser --> Valid token and invalid user
//   - http://localhost:7700/register/nonsense --> Invalid token (any unknown token should trigger this)

import { parse as parseQueryString } from 'query-string';
import { LTRIM } from '../../../globals/validations.js';
// Used for getting Regex
import appSettings from '../../../globals/appSettings.js';
import { getIdpLoginUrl } from '../../sagas/preRender/auth/getCredentials/redirectToIdP.saga.js';
import { sanitizeUrl } from '../../../utils/url';
import { isIosWebview, isAndroidWebview } from '../../../utils/device';
import {
  isOlbOdsMode,
  getCurrentPlatform,
  epsPlatformOlb,
  epsPlatformOrb,
  epsPlatformOlbOffline,
  isCesMode
} from '../../../utils/platform';

export const SET_NEW_REGISTRATION = 'registration/SET_NEW_REGISTRATION';
export const SET_PARTIAL_VIA_TOKEN = 'registration/SET_PARTIAL_VIA_TOKEN';
export const SET_PARTIAL_VIA_FINALISE = 'registration/SET_PARTIAL_VIA_FINALISE';

// When we've found a user for a given token:
export const SET_PARTIAL_USER_LOOKED_UP = 'registration/SET_PARTIAL_USER_LOOKED_UP';

const SET_FIRSTNAME = 'registration/SET_FIRSTNAME';
const SET_LASTNAME = 'registration/SET_LASTNAME';
export const SET_USERNAME = 'registration/SET_USERNAME';
const SET_PASSWORD = 'registration/SET_PASSWORD';
const SET_PASSWORD_CONFIRMATION = 'registration/SET_PASSWORD_CONFIRMATION';
const SET_EMAIL_CONFIRMATION = 'registration/SET_EMAIL_CONFIRMATION';
const SET_TERMS_AND_CONDITIONS = 'registration/SET_TERMS_AND_CONDITIONS';
const SET_MARKETING_PREFERENCES_EMAIL = 'registration/SET_MARKETING_PREFERENCES_EMAIL';
const SET_UNDERAGE_ACCEPTED = 'registration/SET_UNDERAGE_ACCEPTED';
const SET_SELF_SELECTED_ROLE = 'registration/SET_SELF_SELECTED_ROLE';
const SET_CLAIMED_SCHOOL = 'registration/SET_CLAIMED_SCHOOL';
const SET_COUNTRYCODE = 'registration/SET_COUNTRYCODE';

const SET_USERNAME_EXISTS = 'registration/SET_USERNAME_EXISTS';

export const VALIDATE_FIRSTNAME = 'registration/VALIDATE_FIRSTNAME';
export const VALIDATE_LASTNAME = 'registration/VALIDATE_LASTNAME';
export const VALIDATE_USERNAME = 'registration/VALIDATE_USERNAME';
export const VALIDATE_PASSWORD = 'registration/VALIDATE_PASSWORD';
export const VALIDATE_EMAIL_CONFIRMATION = 'registration/VALIDATE_EMAIL_CONFIRMATION';
export const VALIDATE_PASSWORD_CONFIRMATION = 'registration/VALIDATE_PASSWORD_CONFIRMATION';
export const VALIDATE_TERMS_AND_CONDITIONS = 'registration/VALIDATE_TERMS_AND_CONDITIONS';
export const VALIDATE_SELF_SELECTED_ROLE = 'registration/VALIDATE_SELF_SELECTED_ROLE';
export const VALIDATE_CLAIMED_SCHOOL = 'registration/VALIDATE_SCHOOL';
export const VALIDATE_COUNTRYCODE = 'registration/VALIDATE_COUNTRYCODE';

export const SUBMIT_FORM = 'registration/SUBMIT_FORM';
export const SIGN_IN = 'registration/SIGN_IN';
export const SEND_EMAIL_INVITATION = 'registration/SEND_EMAIL_INVITATION';
export const SEND_EMAIL_INVITATION_COMPLETE = 'registration/SEND_EMAIL_INVITATION_COMPLETE';
export const RETRY_FORM = 'registration/RETRY_FORM';
const REGISTRATION_COMPLETE = 'registration/REGISTRATION_COMPLETE';
export const RESET_REGISTRATION = 'registration/RESET_REGISTRATION';
export const SIGN_IN_WITH_GOOGLE = 'registration/SIGN_IN_WITH_GOOGLE';
export const SIGN_IN_WITH_MICROSOFT = 'registration/SIGN_IN_WITH_MICROSOFT';
export const SIGN_IN_WITH_APPLE = 'registration/SIGN_IN_WITH_APPLE';
export const EXTEND_REGISTRATION = 'registration/EXTEND_REGISTRATION';

import {
  isDev,
  getIntegrationPlatformKey,
  getGoogleConnectionName,
  getMicrosoftConnectionName,
  getAppleConnectionName
} from '../../../globals/envSettings.js';

export const formStates = {
  FETCHING_PARTIAL_USER: 'FETCHING_PARTIAL_USER',
  INPUTTING_NEW: 'INPUTTING_NEW', // FULL registration form
  INPUTTING_PARTIAL_VIA_TOKEN: 'INPUTTING_PARTIAL_VIA_TOKEN',
  INPUTTING_PARTIAL_VIA_FINALISE_EXISTING: 'INPUTTING_PARTIAL_VIA_FINALISE_EXISTING',
  INPUTTING_PARTIAL_VIA_FINALISE_LEGACY: 'INPUTTING_PARTIAL_VIA_FINALISE_LEGACY',
  SUBMITTING: 'SUBMITTING',
  VERIFICATION: 'VERIFICATION',
  COMPLETE: 'COMPLETE',
  EMAILSENDING: 'EMAILSENDING',
  EMAILSENDCOMPLETE: 'EMAILSENDCOMPLETE',
  EXTEND_REGISTRATION: 'EXTEND_REGISTRATION'
};

import cmsContent from '../../../utils/cmsContent.js';

const initialState = {
  // Registration form status
  formState: formStates.INPUTTING_NEW,

  // Registration form input values and errors
  firstNameValue: '',
  lastNameValue: '',
  userNameValue: '',
  passwordValue: '',
  emailConfirmationValue: '',
  passwordConfirmationValue: '',
  termsAndConditionsValue: false,
  marketingEmailAcceptedValue: undefined,
  underAgeAcceptedValue: undefined,
  userNameExists: false,
  platformValue: '',
  offeredRoleValue: '',
  selfSelectedRoleValue: '',
  claimedSchoolValue: '',
  countryCodeValue: '',

  // Field "is valid" flags
  isFirstNameValid: false,
  isLastNameValid: false,
  isUserNameValid: false,
  isPasswordValid: false,
  isEmailConfirmationValid: false,
  isPasswordConfirmationValid: false,
  isTermsAndConditionsValid: false,
  isSelfSelectedRoleValid: false,
  isClaimedSchoolValid: false,
  isCountryCodeValid: false,

  // Field Error messages
  firstNameError: null,
  lastNameError: null,
  userNameError: null,
  passwordError: null,
  confirmEmailError: null,
  confirmPasswordError: null,
  termsAndConditionsError: null,
  selfSelectedRoleError: null,
  claimedSchoolError: null,
  countryCodeError: null,
  errorOccurred: null,
  registrationStatus: null
};

export default function registration(state = initialState, action = {}) {
  const CMS = cmsContent.registrationPage || {};
  const queryString = parseQueryString(window.location.search) || {};
  const providerId = queryString.providerId;
  let platformCode = providerId ? getIntegrationPlatformKey(providerId) : getCurrentPlatform();
  platformCode = platformCode === epsPlatformOlbOffline ? epsPlatformOlb : platformCode;
  const registerReturnUrl = queryString.returnUrl || '';
  platformCode =
    registerReturnUrl &&
    (registerReturnUrl.indexOf('oxfordreadingbuddy.com') !== -1 ||
      registerReturnUrl.indexOf('oxfordreadingbuddy.oup.com') !== -1)
      ? epsPlatformOrb
      : platformCode;
  let actionValue;
  actionValue = action.value;

  switch (action.type) {
    case SET_NEW_REGISTRATION:
      return {
        ...state,
        formState: formStates.INPUTTING_NEW,
        registrationType: formStates.INPUTTING_NEW,
        errorMessage: null,
        errorCode: null,
        platformCode
      };

    case RETRY_FORM: {
      let formState;
      // console.debug('In registration.reducer.js RETRY_FORM - isDev is set to <%s>', isDev());
      if (state.token) formState = formStates.INPUTTING_PARTIAL_VIA_TOKEN;
      else if (action.user.emailValue || (isDev() && window.location.pathname.indexOf('EXISTING') >= 0))
        formState = formStates.INPUTTING_PARTIAL_VIA_FINALISE_EXISTING;
      else formState = formStates.INPUTTING_PARTIAL_VIA_FINALISE_LEGACY;
      //
      return {
        ...state,
        formState,
        registrationType: formState,
        errorMessage: null,
        errorCode: null
      };
    }

    case SET_PARTIAL_VIA_TOKEN:
      return {
        ...state,
        token: action.token,
        formState: formStates.FETCHING_PARTIAL_USER,
        registrationType: formStates.INPUTTING_PARTIAL_VIA_TOKEN
      };

    case SET_PARTIAL_VIA_FINALISE: {
      // console.debug('In registration.reducer.js SET_PARTIAL_VIA_FINALISE - isDev is set to <%s>', isDev());
      const formState =
        state.userNameValue || (isDev() && window.location.pathname.indexOf('EXISTING') >= 0)
          ? formStates.INPUTTING_PARTIAL_VIA_FINALISE_EXISTING
          : formStates.INPUTTING_PARTIAL_VIA_FINALISE_LEGACY;
      return {
        ...state,
        formState,
        registrationType: formState
      };
    }

    case SET_PARTIAL_USER_LOOKED_UP: {
      let formState;
      const user = action.user;
      let errorOccurred;
      // console.debug('In registration.reducer.js SET_PARTIAL_USER_LOOKED_UP - isDev is set to <%s>', isDev());
      if (action.isNotFound || action.user.error) {
        // Show error message in the completion form:
        formState = formStates.COMPLETE;
        errorOccurred = true;
      } else if (state.token) formState = formStates.INPUTTING_PARTIAL_VIA_TOKEN;
      else if (action.user.email || (isDev() && window.location.pathname.indexOf('EXISTING') >= 0))
        formState = formStates.INPUTTING_PARTIAL_VIA_FINALISE_EXISTING;
      else formState = formStates.INPUTTING_PARTIAL_VIA_FINALISE_LEGACY;

      // Set state for whichever partial journey we're doing:
      return {
        ...state,
        formState,
        registrationType: formState,
        userIdValue: user.userId,
        userNameValue: user.email || '',
        isUserNameValid: !!user.email,
        firstNameValue: user.firstName || '',
        isFirstNameValid: !!user.firstName,
        lastNameValue: user.lastName || '',
        isLastNameValid: !!user.lastName,
        platformValue: user.platform || '',
        offeredRoleValue: user.offeredRole || '',
        errorMessage: user.error && user.error.message,
        errorCode: user.error && user.error.code,
        errorOccurred,
        platformCode
      };
    }

    case SET_FIRSTNAME:
      return {
        ...state,
        firstNameValue: actionValue.replace(LTRIM, ''),
        isFirstNameValid: !!actionValue.trim() && actionValue.length >= 1,
        firstNameError: ''
      };

    case SET_LASTNAME:
      return {
        ...state,
        lastNameValue: actionValue.replace(LTRIM, ''),
        isLastNameValid: !!actionValue.trim() && actionValue.length >= 1,
        lastNameError: null
      };

    case SET_USERNAME:
      actionValue = action.userName.trim();
      return {
        ...state,
        userNameValue: actionValue,
        userNameError: '',
        userNameExists: false,
        isUserNameValid: !!actionValue && !state.userNameExists && appSettings.EMAIL_REGEX.test(actionValue),
        registrationStatus: null
      };

    case SET_USERNAME_EXISTS: {
      let errorMessage;
      if (action.exists) {
        errorMessage =
          state.formState === formStates.INPUTTING_PARTIAL
            ? CMS.username_exists_text
            : CMS.username_exists_sign_in_text;
      } else {
        errorMessage = '';
      }

      const formState = action.formState || state.formState;
      //
      return {
        ...state,
        userNameExists: action.exists,
        isUserNameValid: !errorMessage && state.isUserNameValid,
        userNameError: errorMessage,
        registrationStatus: action.registrationStatus,
        formState
      };
    }

    case SET_PASSWORD: {
      const isPasswordValid = !!actionValue && appSettings.PASSWORD_REGEX.test(actionValue);
      const isPasswordConfirmationValid = isPasswordValid && actionValue === state.passwordConfirmationValue;
      return {
        ...state,
        passwordValue: actionValue,
        isPasswordValid,
        isPasswordConfirmationValid,
        passwordError: '',
        passwordConfirmationError: isPasswordConfirmationValid ? '' : state.passwordConfirmationError
      };
    }

    case SET_EMAIL_CONFIRMATION: {
      return {
        ...state,
        emailConfirmationValue: actionValue,
        isEmailConfirmationValid: !!actionValue && state.isUserNameValid && actionValue === state.userNameValue,
        emailConfirmationError: ''
      };
    }

    case SET_PASSWORD_CONFIRMATION: {
      return {
        ...state,
        passwordConfirmationValue: actionValue,
        isPasswordConfirmationValid: !!actionValue && state.isPasswordValid && actionValue === state.passwordValue,
        passwordConfirmationError: ''
      };
    }

    case SET_TERMS_AND_CONDITIONS:
      return {
        ...state,
        termsAndConditionsValue: actionValue,
        isTermsAndConditionsValid: actionValue
      };

    case SET_MARKETING_PREFERENCES_EMAIL:
      return {
        ...state,
        marketingEmailAcceptedValue: actionValue
      };

    case SET_UNDERAGE_ACCEPTED:
      return {
        ...state,
        underAgeAcceptedValue: actionValue
      };

    case SET_SELF_SELECTED_ROLE:
      return {
        ...state,
        selfSelectedRoleValue: actionValue.replace(LTRIM, ''),
        isSelfSelectedRoleValid: !!actionValue.trim() && actionValue.length >= 1,
        selfSelectedRoleError: ''
      };

    case SET_CLAIMED_SCHOOL:
      return {
        ...state,
        claimedSchoolValue: actionValue.replace(LTRIM, ''),
        isClaimedSchoolValid: !!actionValue.trim() && actionValue.length >= 1,
        claimedSchoolError: ''
      };

    case SET_COUNTRYCODE:
      return {
        ...state,
        countryCodeValue: actionValue.replace(LTRIM, ''),
        isCountryCodeValid: !!actionValue.trim() && actionValue.length === 2,
        countryCodeError: ''
      };

    case VALIDATE_FIRSTNAME: {
      actionValue = state.firstNameValue.trim();
      const invalidCharacters = appSettings.INVALID_CHARACTERS_REGEX.test(actionValue);
      const errorMessage = !invalidCharacters ? CMS.forename_required_text : CMS.invalid_name_error;
      return {
        ...state,
        firstNameError: actionValue && actionValue.length >= 1 && !invalidCharacters ? '' : errorMessage,
        isFirstNameValid: !!actionValue && !invalidCharacters
      };
    }

    case VALIDATE_LASTNAME: {
      actionValue = state.lastNameValue.trim();
      const invalidCharacters = appSettings.INVALID_CHARACTERS_REGEX.test(actionValue);
      const errorMessage = !invalidCharacters ? CMS.surname_required_text : CMS.invalid_name_error;
      return {
        ...state,
        lastNameError: actionValue && actionValue.length >= 1 && !invalidCharacters ? '' : errorMessage,
        isLastNameValid: !!actionValue && !invalidCharacters
      };
    }

    case VALIDATE_USERNAME: {
      actionValue = state.userNameValue;
      let errorMessage = '';
      if (!actionValue) errorMessage = CMS.email_required_text;
      else if (!appSettings.EMAIL_REGEX.test(actionValue)) errorMessage = CMS.invalid_email_text;
      // else the registration.saga checks whether the email address is already registered.

      return {
        ...state,
        userNameError: errorMessage,
        isUserNameValid: errorMessage === '',
        registrationStatus: null
      };
    }

    case VALIDATE_PASSWORD: {
      actionValue = state.passwordValue;
      let errorMessage;
      errorMessage = '';

      if (!actionValue) errorMessage = CMS.password_required_text;
      else if (!appSettings.PASSWORD_REGEX.test(actionValue)) errorMessage = CMS.password_invalid_text;

      return {
        ...state,
        passwordError: errorMessage,
        isPasswordValid: !errorMessage
      };
    }

    case VALIDATE_PASSWORD_CONFIRMATION: {
      actionValue = state.passwordValue;
      let errorMessage;
      errorMessage = '';

      if (!actionValue) errorMessage = CMS.confirm_password_text;
      else if (actionValue !== state.passwordConfirmationValue) errorMessage = CMS.passwords_not_equal_error;

      return {
        ...state,
        passwordConfirmationError: errorMessage,
        isPasswordConfirmationValid: !errorMessage && state.isPasswordValid
      };
    }

    case VALIDATE_EMAIL_CONFIRMATION: {
      actionValue = state.userNameValue;
      let errorMessage;
      errorMessage = '';

      if (!actionValue) errorMessage = CMS.confirm_email_text;
      else if (action.pastedValue) errorMessage = CMS.retype_email_text;
      else if (actionValue.toLowerCase() !== state.emailConfirmationValue.toLowerCase())
        errorMessage = CMS.emails_not_equal_error;

      return {
        ...state,
        emailConfirmationError: errorMessage,
        isEmailConfirmationValid: !errorMessage && state.isUserNameValid
      };
    }

    case VALIDATE_TERMS_AND_CONDITIONS:
      actionValue = state.termsAndConditionsValue;

      return {
        ...state,
        termsAndConditionsError: actionValue ? '' : CMS.confirm_terms_error,
        isTermsAndConditionsValid: !!actionValue
      };

    case VALIDATE_SELF_SELECTED_ROLE: {
      actionValue = state.selfSelectedRoleValue.trim();
      const invalidCharacters = appSettings.INVALID_CHARACTERS_REGEX.test(actionValue);
      const errorMessage = !invalidCharacters
        ? CMS.self_selected_role_required_text
        : CMS.invalid_self_selected_role_text;
      return {
        ...state,
        selfSelectedRoleError: actionValue && actionValue.length >= 1 && !invalidCharacters ? '' : errorMessage,
        isSelfSelectedRoleValid: !!actionValue && !invalidCharacters
      };
    }

    case VALIDATE_CLAIMED_SCHOOL: {
      actionValue = state.claimedSchoolValue.trim();
      const invalidCharacters = appSettings.INVALID_CHARACTERS_REGEX.test(actionValue);
      const errorMessage = !invalidCharacters ? CMS.claimed_school_required_text : CMS.invalid_claimed_school_text;
      return {
        ...state,
        claimedSchoolError: actionValue && actionValue.length >= 1 && !invalidCharacters ? '' : errorMessage,
        isClaimedSchoolValid: !!actionValue && !invalidCharacters
      };
    }

    case VALIDATE_COUNTRYCODE: {
      actionValue = state.countryCodeValue.trim();
      const invalidCharacters = appSettings.INVALID_CHARACTERS_REGEX.test(actionValue);
      const errorMessage = !invalidCharacters ? CMS.country_code_required_text : CMS.invalid_country_code_text;
      return {
        ...state,
        countryCodeError: actionValue && actionValue.length === 2 && !invalidCharacters ? '' : errorMessage,
        isCountryCodeValid: !!actionValue && !invalidCharacters
      };
    }

    case SUBMIT_FORM:
      return {
        ...state,
        formState: formStates.SUBMITTING
      };

    case SIGN_IN_WITH_GOOGLE:
    case SIGN_IN_WITH_APPLE:
    case SIGN_IN_WITH_MICROSOFT: {
      const redirectTo = queryString.target_url;

      const returnUrl = sanitizeUrl(decodeURIComponent(queryString.returnUrl || ''));
      let connection;

      if (action.type === SIGN_IN_WITH_GOOGLE) connection = getGoogleConnectionName();
      else if (action.type === SIGN_IN_WITH_MICROSOFT) connection = getMicrosoftConnectionName();
      else if (action.type === SIGN_IN_WITH_APPLE) connection = getAppleConnectionName();

      // On OBL mobile apps we need to trigger an event in the embedded webview to get the Google auth workin fine.
      if (
        (isIosWebview() || isAndroidWebview() || isOlbOdsMode()) &&
        window &&
        // @ts-ignore
        window.olbOfflineClient &&
        // @ts-ignore
        typeof window.olbOfflineClient.continueWithConnection === 'function'
      ) {
        // trigger the OLB Google sign-up function
        // @ts-ignore
        window.olbOfflineClient.continueWithConnection(connection);

        return {
          ...state
        };
      }

      const params = { connection, withReturnTo: false, isSignUp: true };

      if (redirectTo) {
        params.targetUrl = window.location.pathname + window.location.search;
        if (providerId) {
          params.platform = getIntegrationPlatformKey(providerId);
        }
      } else if (returnUrl) {
        // use targetUrl option (for internal paths)
        params.targetUrl = returnUrl.replace(`${window.location.origin}/`, '');
      }

      return getIdpLoginUrl(null, params).then(signInUrl => {
        if (isCesMode()) {
          window.location.href = `${signInUrl}&oup-idp-ui=${params.platform}`;
        } else {
          window.location.href = signInUrl;
        }

        return {
          ...state
        };
      });
    }
    case SIGN_IN:
      getIdpLoginUrl(null, { withReturnTo: false }).then(url => {
        window.location.href = url;
      });
      return {
        ...state
      };

    case SEND_EMAIL_INVITATION:
      return {
        ...state,
        formState: formStates.EMAILSENDING
      };

    case SEND_EMAIL_INVITATION_COMPLETE:
      return {
        ...state,
        formState: formStates.EMAILSENDCOMPLETE,
        errorOccurred: action.errorOccurred
      };

    case REGISTRATION_COMPLETE:
      return {
        ...state,
        formState: formStates.COMPLETE,
        errorOccurred: action.errorOccurred,
        userIdValue: action.userId,
        errorCode: action.errorCode
      };

    case EXTEND_REGISTRATION:
      return {
        ...state,
        formState: formStates.EXTEND_REGISTRATION,
        token: action.token
      };

    case RESET_REGISTRATION:
      return initialState;
    default:
      return state;
  }
}

export const setFullRegistration = () => ({
  type: SET_NEW_REGISTRATION
});

export const setPartialRegViaToken = token => ({
  type: SET_PARTIAL_VIA_TOKEN,
  token
});

export const setPartialRegViaFinalise = emailValue => ({
  type: SET_PARTIAL_VIA_FINALISE,
  emailValue
});

export const setPartialRegUserLookedUp = (user, isNotFound) => ({
  type: SET_PARTIAL_USER_LOOKED_UP,
  user,
  isNotFound
});

export const setFirstName = value => ({
  type: SET_FIRSTNAME,
  value
});

export const setLastName = value => ({
  type: SET_LASTNAME,
  value
});

export const setUserName = userName => ({
  type: SET_USERNAME,
  userName
});

export const setUserNameExists = (data, error) => ({
  type: SET_USERNAME_EXISTS,
  ...data,
  error
});

export const setPassword = value => ({
  type: SET_PASSWORD,
  value
});

export const setPasswordConfirmation = value => ({
  type: SET_PASSWORD_CONFIRMATION,
  value
});

export const setEmailConfirmation = value => ({
  type: SET_EMAIL_CONFIRMATION,
  value
});

export const setTermsAndConditions = value => ({
  type: SET_TERMS_AND_CONDITIONS,
  value
});

export const setMarketPref = value => ({
  type: SET_MARKETING_PREFERENCES_EMAIL,
  value
});

export const setUnderAgeAccepted = value => ({
  type: SET_UNDERAGE_ACCEPTED,
  value
});

export const setSelfSelectedRole = value => ({
  type: SET_SELF_SELECTED_ROLE,
  value
});

export const setClaimedSchool = value => ({
  type: SET_CLAIMED_SCHOOL,
  value
});

export const setCountryCode = value => ({
  type: SET_COUNTRYCODE,
  value
});

export const validateFirstName = () => ({ type: VALIDATE_FIRSTNAME });
export const validateLastName = () => ({ type: VALIDATE_LASTNAME });
export const validateUserName = () => ({ type: VALIDATE_USERNAME });
export const validatePassword = () => ({ type: VALIDATE_PASSWORD });
export const validatePasswordConfirmation = () => ({ type: VALIDATE_PASSWORD_CONFIRMATION });
export const validateEmailConfirmation = (_, __, pastedValue) => ({
  type: VALIDATE_EMAIL_CONFIRMATION,
  pastedValue: typeof pastedValue === 'string' ? pastedValue : undefined
});
export const validateTermsAndConditions = () => ({
  type: VALIDATE_TERMS_AND_CONDITIONS
});

export const validateSelfSelectedRole = () => ({ type: VALIDATE_SELF_SELECTED_ROLE });
export const validateClaimedSchool = () => ({ type: VALIDATE_CLAIMED_SCHOOL });
export const validateCountryCode = () => ({ type: VALIDATE_COUNTRYCODE });

// Form state actions
export const submitForm = () => ({
  type: SUBMIT_FORM
});

export const signIn = () => ({
  type: SIGN_IN
});

// Form send mail actions
export const sendEmailInvitation = () => ({
  type: SEND_EMAIL_INVITATION
});

export const sendEmailInvitationComplete = errorOccurred => ({
  type: SEND_EMAIL_INVITATION_COMPLETE,
  errorOccurred
});

export const retryForm = () => ({
  type: RETRY_FORM
});

export const registrationComplete = (errorOccurred, userId, errorCode) => ({
  type: REGISTRATION_COMPLETE,
  errorOccurred,
  userId,
  errorCode
});

export const resetRegistration = () => ({
  type: RESET_REGISTRATION
});

export const googleSignIn = () => ({
  type: SIGN_IN_WITH_GOOGLE
});

export const microsoftSignIn = () => ({
  type: SIGN_IN_WITH_MICROSOFT
});

export const appleSignIn = () => ({
  type: SIGN_IN_WITH_APPLE
});

export const setExtendRegistration = token => ({
  type: EXTEND_REGISTRATION,
  token
});
