import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import HubModalLayout from '../../HubModalLayout/HubModalLayout';
import Button, { buttonTypes } from '../../Button/Button';
import styles from '../../PlacementTestOnBoardingWizard/PlacementTestOnboardingModal/PlacementTestOnboardingModal.scss';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { closeSelfRegisteredWizard } from '../../../redux/actions/hubUi';

function StopSettingUpOrgModal({
  localizedContent: { hubGlossary: hubContent, selfRegisteredUserOnboardingWizard: content },
  isOpen,
  closeWarningModal,
  closeSelfRegisteredWizardAction
}) {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) closeWarningModal();
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className={`${styles.outerWizardWarningModal} ${isOpen ? styles.open : ''}`}>
      <HubModalLayout isOpen={isOpen} closeAction={closeWarningModal} ariaLabel={hubContent.warning_wizard_arialabel}>
        <div
          data-testid="ONBOARDING_WIZARD_WARNING_MODAL"
          className={`${styles.modalContainer} ${isOpen ? styles.animationFadeIn : ''}`}
          aria-hidden={!isOpen}
          ref={modalRef}
        >
          <div className={styles.modalHeader}>
            <Button
              dataTestId="ONBOARDING_WIZARD_WARNING_MODAL_CLOSE_BUTTON"
              text={hubContent.close}
              type={buttonTypes.CLOSE_BORDER_GRAY}
              onClick={e => {
                e.stopPropagation();
                closeWarningModal();
              }}
            />
          </div>

          <div className={styles.modalContent}>
            <p className={styles.title}>{content.self_registered_user_warning_modal_title}</p>
            <p className={styles.subtitle}>{content.self_registered_user_warning_modal_subtext}</p>
          </div>

          <div className={styles.modalFooter}>
            <Button
              dataTestId="ONBOARDING_WIZARD_WARNING_MODAL_CANCEL_BUTTON"
              text={hubContent.cancel}
              type={buttonTypes.CANCEL}
              onClick={() => closeWarningModal()}
            />
            <Button
              dataTestId="ONBOARDING_WIZARD_WARNING_MODAL_CONFIRM_BUTTON"
              text={hubContent.yes}
              type={buttonTypes.PRIMARY}
              onClick={() => closeSelfRegisteredWizardAction()}
            />
          </div>
        </div>
      </HubModalLayout>
    </div>
  );
}
StopSettingUpOrgModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeWarningModal: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired,
  closeSelfRegisteredWizardAction: PropTypes.func
};

export default compose(
  connect(null, {
    closeSelfRegisteredWizardAction: closeSelfRegisteredWizard
  }),
  withLocalizedContent('hubGlossary', 'selfRegisteredUserOnboardingWizard')
)(StopSettingUpOrgModal);
