import SessionStorage from './storage/Session';
import { isEmbeddedInIframe } from './platform';
import { featureIsEnabled } from '../globals/envSettings';

/**
 * Auth0 SPA library doesn't have a way to retrieve the refresh token ( but it uses it internally ). It might not be needed here at all.
 */

const SessionInfo = {
  // Legacy
  email: null,
  userId: null,
  currentRole: null,
  currentOrganisationId: null,
  registrationStatus: null,
  orgRoleMap: {},

  accessKeyId: null,
  secretAccessKey: null,
  sessionToken: null,
  expiryTime: null,
  rightSuiteToken: null,

  oidcIdToken: null,
  oidcAccessToken: null,
  oidcRefreshToken: null,
  oidcExpiresIn: 0,
  oidcSessionClock: 0,

  doOrgSwitch: false,

  // New
  saml: {
    accessKeyId: null,
    secretAccessKey: null,
    sessionToken: null,
    expiryTime: null,
    rightSuiteToken: null
  },
  oidc: {
    idToken: null,
    accessToken: null,
    refreshToken: null,
    expiresIn: 0,
    sessionClock: 0
  }
};

//
class Session {
  constructor() {
    let storageKey = 'ces-creds';

    if (isEmbeddedInIframe()) {
      storageKey = 'frame-creds';
    }

    console.log(`[Session] Using key: ${storageKey}`);

    this._storage = new SessionStorage(storageKey);
  }

  exists() {
    return this._storage.exists();
  }

  set(sessionInfo = SessionInfo) {
    if (featureIsEnabled('single-client-session-store')) {
      console.log('[Session] Refactored info');

      // Remove any other key, except the specified ones
      const restrictedKeys = ['saml', 'oidc'];
      Object.keys(sessionInfo).forEach(key => {
        if (!restrictedKeys.includes(key)) {
          delete sessionInfo[key];
        }
      });
    }

    this._storage.set(sessionInfo);
  }

  get() {
    return { ...JSON.parse(JSON.stringify(SessionInfo)), ...this._storage.get() };
  }

  clear() {
    this._storage.clear();
  }
}

export default new Session();
