import { getEnvType } from '../../globals/envSettings';

export default function getOptProductsCatalogueUrl(orgId) {
  if (!orgId) {
    throw new Error('orgId is required');
  }

  const environment = getEnvType().toLowerCase();
  const isProd = environment === 'prod';
  const goDomainPrefix = isProd ? '' : `${environment}.`;

  return `https://${goDomainPrefix}go.oup.com/placement-test/products?orgId=${orgId}`;
}
