import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import PlacementTestEditSessionName from './PlacementTestEditSessionName';
import PlacementTestEditTestSchedule from './PlacementTestEditTestSchedule';
import PlacementTestPanelSummary from './PlacementTestPanelSummary';
import PopoutPanelIllustrationHeading from '../../../../components/PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading';
import { HubIllustrationAltText, HubIllustrationConstants } from '../../../../globals/hubConstants';
import PopoutPanelIconHeading, { types } from '../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import { clearWizardState } from '../../../../redux/actions/placementTestOnboardingWizard';
import { closePlacementTestPanel } from '../../../../redux/actions/hubUi';
import ScrollContainer from '../../../../components/ScrollContainer/ScrollContainer';
import PopoutActionFooter from '../../../../components/PopoutActionFooter/PopoutActionFooter';
import { SidePanel } from '../../../../components';
import PanelHeading from '../../../../components/PanelHeading/PanelHeading';
import PlacementTestEditShowResults from './PlacementTestEditShowResults';
import PlacementTestEditLanguageVariation from './PlacementTestEditLanguageVariation';
import {
  refreshPlacementTestList,
  closeEditJoiningCodePanel,
  editJoiningCodeUpdatedField
} from '../../../../redux/actions/placementTests';
import { formatDate } from '../../../../components/PlacementTestOnBoardingWizard/utils/helpers';
import ManageAddedStudentsPanel from '../../../../components/PlacementTestOnBoardingWizard/AddStudents/ManageAddedStudentsPanel/ManageAddedStudentsPanel';
import PlacementTestChooseTypeOfTestTakerPanel from '../../../../components/PlacementTestOnBoardingWizard/AddStudents/PlacementTestChooseTypeOfTestTakerPanel/PlacementTestChooseTypeOfTestTakerPanel';
import ManageJoiningCode from './ManageJoiningCode/ManageJoiningCode';
import PlacementTestEditNotificationsAndReminders from './PlacementTestEditNotificationsAndReminders';

const getCurrentTestCodeIdAndPlaces = (placementTests, panelSessionName) => {
  const testSessionTypeKeys = Object.keys(placementTests);
  const sessions = [];

  testSessionTypeKeys?.forEach(key => {
    if (placementTests[key].length && key !== 'DRAFT') {
      sessions.push(...placementTests[key]);
    }
  });

  const test = sessions.find(item => item.classGroup.className === panelSessionName);
  const placesForUnknownStudents = test?.configurations?.CONFIGURATION_PLACEMENT_TEST?.placesForUnknownStudents;
  const joiningCode = test?.joiningCode;
  const classCodeId = test?.joiningCode?.code;
  return { classCodeId, placesForUnknownStudents, joiningCode };
};

function PlacementTestPanel({
  isOpen,
  closePlacementTestPanelAction,
  placementTestSessionNameValue,
  placementTestSessionCreationLoading,
  clearWizardStateAction,
  refreshPlacementTestListAction,
  localizedContent: { placementTests: placementTestsContent, hubGlossary: hubContent },
  placementTestSessionDateCreated,
  studentsList = [],
  editJoiningCode,
  closeEditJoiningCodePanelAction,
  editJoiningCodeUpdatedFieldAction,
  testsSessions
}) {
  const [isOpenSessionNamePanel, setIsOpenSessionNamePanel] = useState(false);
  const [isOpenConfirmationPanel, setIsOpenConfirmationPanel] = useState(false);
  const [panelSessionName, setPanelSessionName] = useState('');
  const [isOpenShowResultsPanel, setIsOpenShowResultsPanel] = useState(false);
  const [isOpenNotificationsAndReminders, setIsOpenNotificationsAndReminders] = useState(false);
  const [isOpenLanguageVariationPanel, setIsOpenLanguageVariationPanel] = useState(false);
  const [isOpenManageTestSchedulePanel, setIsOpenManageTestSchedulePanel] = useState(false);
  const [isOpenManageAddedStudentsPanel, setIsOpenManageAddedStudentsPanel] = useState(false);
  const [isChooseTestTakerPanelOpen, setIsChooseTestTakerPanelOpen] = useState(false);
  const showPlacementTestSummary =
    !isOpenConfirmationPanel &&
    !placementTestSessionCreationLoading &&
    !isOpenSessionNamePanel &&
    !isOpenNotificationsAndReminders &&
    !isOpenManageTestSchedulePanel &&
    !isOpenLanguageVariationPanel &&
    !isOpenManageAddedStudentsPanel &&
    !isOpenShowResultsPanel &&
    !isChooseTestTakerPanelOpen &&
    !editJoiningCode;

  const handleClose = () => {
    if (showPlacementTestSummary) {
      clearWizardStateAction();
      closePlacementTestPanelAction();
    } else {
      setIsOpenConfirmationPanel(false);
      setIsOpenSessionNamePanel(false);
      setIsOpenShowResultsPanel(false);
      setIsOpenLanguageVariationPanel(false);
      setIsOpenManageTestSchedulePanel(false);
      setIsOpenManageAddedStudentsPanel(false);
      setIsOpenNotificationsAndReminders(false);
      closeEditJoiningCodePanelAction();
      editJoiningCodeUpdatedFieldAction();
    }
  };

  const editPlacementTestActions = {
    onClickSessionNameHandler: () => {
      setIsOpenSessionNamePanel(true);
    },
    onClickSessionLanguageVariationHandle: () => {
      setIsOpenLanguageVariationPanel(true);
    },
    onClickSessionListeningAccentsHandle: () => {
      setIsOpenLanguageVariationPanel(true);
    },
    onClickSessionStartDateHandle: () => {
      setIsOpenManageTestSchedulePanel(true);
    },
    onClickSessionEndDateHandle: () => {
      setIsOpenManageTestSchedulePanel(true);
    },
    onClickSessionExtraTimeHandle: () => {
      setIsOpenManageTestSchedulePanel(true);
    },
    onClickSessionAddStudentsHandle: () => {
      setIsOpenManageAddedStudentsPanel(true);
    },
    onClickSessionAddLicensesHandle: () => {},
    onClickSessionSendEmailToStudentsHandle: () => {
      setIsOpenNotificationsAndReminders(true);
    },
    onClickSessionRemindersHandle: () => {
      setIsOpenNotificationsAndReminders(true);
    },
    onClickSessionShowResultsHandle: () => {
      setIsOpenShowResultsPanel(true);
    }
  };

  useEffect(() => {
    setPanelSessionName(placementTestSessionNameValue);
  }, [isOpen]);

  const { classCodeId, placesForUnknownStudents, joiningCode } = getCurrentTestCodeIdAndPlaces(
    testsSessions,
    panelSessionName
  );

  return (
    <SidePanel
      id="ManagePlacementTest"
      isOpen={isOpen}
      onClose={handleClose}
      ariaLabel="manage placement test"
      hideCloseButton={!!placementTestSessionCreationLoading}
    >
      {isOpenConfirmationPanel && !placementTestSessionCreationLoading && (
        <ScrollContainer
          footerContent={
            <PopoutActionFooter
              primaryButtonText={hubContent.done_button}
              primaryButtonAction={() => {
                setIsOpenSessionNamePanel(false);
                setIsOpenNotificationsAndReminders(false);
                setIsOpenManageTestSchedulePanel(false);
                setIsOpenShowResultsPanel(false);
                setIsOpenConfirmationPanel(false);
                setIsOpenLanguageVariationPanel(false);
                setIsOpenManageAddedStudentsPanel(false);
                refreshPlacementTestListAction();
              }}
            />
          }
        >
          <PopoutPanelIllustrationHeading
            title={placementTestsContent.placement_test_changes_have_been_saved}
            illustrationSrc={HubIllustrationConstants.SUCCESS}
            illustrationAltText={HubIllustrationAltText.SUCCESS}
          />
        </ScrollContainer>
      )}
      {placementTestSessionCreationLoading && (
        <ScrollContainer>
          <PopoutPanelIconHeading
            type={types.LOADING}
            title={placementTestsContent.placement_test_loading_title}
            subtitle={placementTestsContent.placement_test_loading_text}
          />
        </ScrollContainer>
      )}
      {isOpenSessionNamePanel && (
        <PlacementTestEditSessionName
          panelSessionName={panelSessionName}
          cancelSessionName={(e, editedSessionName) => {
            e.preventDefault();
            setIsOpenSessionNamePanel(false);
            if (editedSessionName) {
              setIsOpenConfirmationPanel(true);
              setPanelSessionName(placementTestSessionNameValue);
            }
          }}
        />
      )}
      {isOpenNotificationsAndReminders && (
        <PlacementTestEditNotificationsAndReminders
          panelSessionName={panelSessionName}
          cancelNotificationsAndReminders={(e, editedNotificationsAndReminders) => {
            e.preventDefault();
            setIsOpenNotificationsAndReminders(false);
            if (editedNotificationsAndReminders) setIsOpenConfirmationPanel(true);
          }}
        />
      )}
      {isOpenShowResultsPanel && (
        <PlacementTestEditShowResults
          panelSessionName={panelSessionName}
          cancelShowResults={(e, editedShowResultsToStudents) => {
            e.preventDefault();
            setIsOpenShowResultsPanel(false);
            if (editedShowResultsToStudents) setIsOpenConfirmationPanel(true);
          }}
        />
      )}
      {isOpenLanguageVariationPanel && (
        <PlacementTestEditLanguageVariation
          panelSessionName={panelSessionName}
          cancelLanguageVariationPanel={(e, editedLanguageVariation) => {
            e.preventDefault();
            setIsOpenLanguageVariationPanel(false);
            if (editedLanguageVariation) setIsOpenConfirmationPanel(true);
          }}
        />
      )}
      {isOpenManageTestSchedulePanel && (
        <PlacementTestEditTestSchedule
          panelSessionName={panelSessionName}
          cancelTestSchedulePanel={(e, editedTestSchedule) => {
            e.preventDefault();
            setIsOpenManageTestSchedulePanel(false);
            if (editedTestSchedule) setIsOpenConfirmationPanel(true);
          }}
        />
      )}
      {isOpenManageAddedStudentsPanel && (
        <ManageAddedStudentsPanel
          placementTestSessionName={panelSessionName}
          isOpen={isOpenManageAddedStudentsPanel}
          closePanel={e => {
            e.preventDefault();
            setIsOpenManageAddedStudentsPanel(false);
            handleClose();
          }}
          cancelPanel={e => {
            e.preventDefault();
            setIsOpenManageAddedStudentsPanel(false);
          }}
          onAddStudents={e => {
            e.preventDefault();
            setIsOpenManageAddedStudentsPanel(false);
            setIsChooseTestTakerPanelOpen(true);
          }}
          failed={false}
          isManageSessionEditPanel
          studentsList={studentsList}
        />
      )}
      {isChooseTestTakerPanelOpen && (
        <PlacementTestChooseTypeOfTestTakerPanel
          onClose={() => {
            setIsChooseTestTakerPanelOpen(false);
            handleClose();
          }}
          cancelPanel={e => {
            e.preventDefault();
            setIsOpenManageAddedStudentsPanel(true);
            setIsChooseTestTakerPanelOpen(false);
          }}
          isManageSessionEditPanel
        />
      )}
      {editJoiningCode && <ManageJoiningCode placesRemainingInSession={placesForUnknownStudents} />}
      {showPlacementTestSummary && (
        <ScrollContainer
          headerContent={
            <PanelHeading
              title={panelSessionName}
              subtitle={`${hubContent.placementTestSessionCreatedOn} ${formatDate(
                placementTestSessionDateCreated,
                false
              )}`}
            />
          }
          footerContent={
            <PopoutActionFooter primaryButtonText={hubContent.done_button} primaryButtonAction={handleClose} />
          }
        >
          <PlacementTestPanelSummary
            editPlacementTestActions={editPlacementTestActions}
            panelSessionName={panelSessionName}
            placementTestsContent={placementTestsContent}
            classCode={classCodeId}
            placesRemainingInSession={placesForUnknownStudents}
            joiningCode={joiningCode}
          />
        </ScrollContainer>
      )}
    </SidePanel>
  );
}

PlacementTestPanel.propTypes = {
  isOpen: PropTypes.func.isRequired,
  closePlacementTestPanelAction: PropTypes.func.isRequired,
  placementTestSessionNameValue: PropTypes.string,
  placementTestSessionCreationLoading: PropTypes.bool,
  clearWizardStateAction: PropTypes.func,
  refreshPlacementTestListAction: PropTypes.func,
  localizedContent: PropTypes.object,
  placementTestSessionDateCreated: PropTypes.string,
  studentsList: PropTypes.array,
  editJoiningCode: PropTypes.bool,
  closeEditJoiningCodePanelAction: PropTypes.func,
  editJoiningCodeUpdatedFieldAction: PropTypes.func,
  testsSessions: PropTypes.object
};

export default compose(
  withLocalizedContent('hubGlossary', 'placementTests'),
  connect(
    ({
      placementTestSessionCreate,
      placementOnboardingWizard,
      loadPlacementTestsReducer: { editJoiningCode, placementTests }
    }) => ({
      placementTestSessionNameValue: placementTestSessionCreate.placementTestSessionNameValue,
      studentsList: placementTestSessionCreate.placementTest.studentsList,
      placementTestSessionCreationLoading: placementOnboardingWizard.placementTestSessionCreationLoading,
      placementTestSessionDateCreated: placementTestSessionCreate.placementTestSessionDateCreated,
      editJoiningCode,
      testsSessions: placementTests
    }),
    {
      closePlacementTestPanelAction: closePlacementTestPanel,
      clearWizardStateAction: clearWizardState,
      refreshPlacementTestListAction: refreshPlacementTestList,
      closeEditJoiningCodePanelAction: closeEditJoiningCodePanel,
      editJoiningCodeUpdatedFieldAction: editJoiningCodeUpdatedField
    }
  )
)(PlacementTestPanel);
