import PropTypes from 'prop-types';
import React from 'react';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import Button from '@oup/shared-front-end/src/components/Button';
import { ICON_LEFT, ICON_RIGHT } from '@oup/shared-front-end/src/svg/oup/index';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './Headers.scss';
import { useScroll } from '../GradebookTable/ScrollContext';
import { featureIsEnabled } from '../../globals/envSettings';
import GradebookTable from '../GradebookTable/GradebookTable';

const ROW_HEIGHT = 58;
function Headers({ levels = [], location, localizedContent = {}, fixColumn }) {
  const { scrollingElement } = useScroll();
  const scroll = direction => {
    if (scrollingElement) {
      scrollingElement(direction);
    }
  };

  return (
    <GradebookTable.Row>
      <GradebookTable.Header height={ROW_HEIGHT} stickyColumn>
        {fixColumn ? (
          <Button variant="outline" icon={{ component: <ICON_LEFT /> }} onClick={() => scroll('right')} />
        ) : (
          `&nbsp;`
        )}
      </GradebookTable.Header>
      <GradebookTable.Header height={ROW_HEIGHT} width={170}>
        {featureIsEnabled('replacing-last-accessed-with-last-opened') ? (
          <div>Last Opened</div>
        ) : (
          <div>Last Accessed</div>
        )}
      </GradebookTable.Header>
      <GradebookTable.Header height={ROW_HEIGHT} colSpan={2}>
        Total
      </GradebookTable.Header>
      {levels.map(({ name, uId, isActivity, locked }) => (
        <GradebookTable.Header height={ROW_HEIGHT} key={uId} colSpan={2} locked={locked}>
          {!isActivity ? (
            <TextLink
              to={`${location.pathname.replace(/\/$/, '')}#${encodeURIComponent(uId)}`}
              component={RouterLink}
              {...(locked && {
                dataAttributes: { tooltip: localizedContent?.locked_node_tooltip_text || '' },
                leftIcon: <SVGIcon glyph={GLYPHS.ICON_LOCK_24} />
              })}
            >
              {name}
            </TextLink>
          ) : (
            <span
              {...(locked && {
                className: styles.lockedIcon,
                'data-tooltip': localizedContent?.locked_node_tooltip_text
              })}
            >
              {locked && <SVGIcon glyph={GLYPHS.ICON_LOCK_24} />}
              {name}
            </span>
          )}
        </GradebookTable.Header>
      ))}
      <GradebookTable.Header fixColumn={fixColumn}>
        <Button variant="outline" icon={{ component: <ICON_RIGHT /> }} onClick={() => scroll('left')} />
      </GradebookTable.Header>
    </GradebookTable.Row>
  );
}
Headers.propTypes = {
  levels: PropTypes.array,
  location: PropTypes.object.isRequired,
  localizedContent: PropTypes.object,
  fixColumn: PropTypes.bool
};

export default withRouter(Headers);
