/** Placement test session reducer
 */
import cmsContent from '../../utils/cmsContent.js';
import { isHubMode } from '../../utils/platform.js';

// Name input actions
const VALIDATED_PLACEMENT_TEST_SESSION_NAME = 'placementTestSessionCreate/VALIDATED_CLASSROOM_NAME';
const VALIDATE_PLACEMENT_TEST_SESSION_NAME_ON_BLUR = 'placementTestSessionCreate/VALIDATE_CLASSROOM_NAME_ON_BLUR';
export const SET_PLACEMENT_TEST_SESSION_NAME = 'placementTestSessionCreate/SET_PLACEMENT_TEST_SESSION_NAME';
export const SET_PLACEMENT_TEST_SESSION_NAME_EDIT = 'placementTestSessionCreate/SET_PLACEMENT_TEST_SESSION_NAME_EDIT';
export const PLACEMENT_TEST_SESSION_NAME_ON_BLUR = 'placementTestSessionCreate/PLACEMENT_TEST_SESSION_NAME_ON_BLUR';

const CLEAR_PRESELECTED_TEACHER = 'placementTestSessionCreate/CLEAR_PRESELECTED_TEACHER';
const SET_PRESELECTED_TEACHER = 'placementTestSessionCreate/SET_PRESELECTED_TEACHER';
const SET_PLACEMENT_TEST_PRODUCT_CHANGED = 'placementTestSessionCreate/SET_PLACEMENT_TEST_PRODUCT_CHANGED';

// Form submission and response
export const CREATE_PLACEMENT_TEST_SESSION = 'placementTestSessionCreate/CREATE_PLACEMENT_TEST_SESSION';
const SHOW_CONFIRMATION = 'placementTestSessionCreate/SHOW_CONFIRMATION';

export const SET_PLACEMENT_TEST = 'placementTestSessionCreate/SET_PLACEMENT_TEST';

// Add students actions
export const SET_SELECTED_LICENSES = 'placementTestSessionCreate/SET_SELECTED_LICENSES';

// save draft test
export const SET_EDITED_PLACEMENT_TEST = 'placementTestSessionCreate/SET_EDITED_PLACEMENT_TEST';

// edit placement test
export const CONTINUE_EDITED_PLACEMENT_TEST = 'placementTestSessionCreate/CONTINUE_EDITED_PLACEMENT_TEST';

// Reset the form
export const CLOSE_FORM = 'placementTestSessionCreate/CLOSE_FORM';

export const SET_ORG_TEST_CREDITS = 'placementTestSessionCreate/SET_ORG_TEST_CREDITS';
export const GET_WIZARD_TEST_CREDITS_REQUEST = 'placementTestSessionCreate/GET_WIZARD_TEST_CREDITS_REQUEST';
export const GET_WIZARD_TEST_CREDITS_SUCCESS = 'placementTestSessionCreate/GET_WIZARD_TEST_CREDITS_SUCCESS';
export const GET_WIZARD_TEST_CREDITS_FAILURE = 'placementTestSessionCreate/GET_WIZARD_TEST_CREDITS_FAILURE';

export const formStates = {
  CHOOSE_PLACEMENT_TEST_SESSION_NAME: 'CHOOSE_PLACEMENT_TEST_SESSION_NAME', // While adding name of new placement test session.
  SUBMITTING: 'SUBMITTING',
  CONFIRMATION: 'CONFIRMATION' // Success|Fail feedback after SUBMITTING
};

const initialState = {
  formState: formStates.CHOOSE_PLACEMENT_TEST_SESSION_NAME,

  // Placement Test Session Name and its validation states
  placementTestSessionNameValue: '',
  placementTestSessionNameValidationMessage: '',
  placementTestSessionNameValidationPending: false,
  placementTestSessionNameValidationIsInvalid: false,
  placementTestSessionNameValidationIsWarning: false,
  placementTestSessionNameValidationIsValid: false,
  placementTestSessionNameIsTouched: false,

  // Results of the creation attempt
  placementTestSessionId: null,
  placementTestSessionDateCreated: null,

  // Test Schedule validation states
  testStartDateIsInvalid: false,
  testEndDateIsInvalid: false,
  extraTimePerItemIsInvalid: false,

  // save draft object
  editedPlacementTest: {},
  placementTest: {}
};

export default function placementTestSessionCreate(state = initialState, action = {}) {
  const CMS = cmsContent.placementTestSessionState || {};
  switch (action.type) {
    case SET_PLACEMENT_TEST_SESSION_NAME: {
      const actualPlacementTestSessionNameLength = action.placementTestSessionName.trim().length;

      return {
        ...state,
        placementTestSessionNameValue: action.placementTestSessionName,
        placementTestSessionNameValidationPending: actualPlacementTestSessionNameLength > 0,
        placementTestSessionNameValidationIsInvalid: actualPlacementTestSessionNameLength === 0,
        placementTestSessionNameValidationIsWarning: false,
        placementTestSessionNameValidationIsValid: false,
        placementTestSessionNameValidationMessage:
          actualPlacementTestSessionNameLength === 0 ? CMS.placement_test_session_name_length_error : ''
      };
    }

    case SET_PRESELECTED_TEACHER:
      // When a user logs in, if they are a teacher we prepare them as a selected user
      return {
        ...state,
        selectedTeacherIds: [action.userId]
      };

    case CLEAR_PRESELECTED_TEACHER:
      // When a user logs in, if they are a teacher we prepare them as a selected user
      return {
        ...state,
        selectedTeacherIds: []
      };

    case VALIDATED_PLACEMENT_TEST_SESSION_NAME: {
      let errorMessage = '';

      // If the name is in use set a message
      if (action.exists) {
        errorMessage = CMS.placement_test_session_name_duplicate;
      } else if (!isHubMode() && action.invalidPlacementTestSessionName) {
        errorMessage = CMS.placement_test_session_name_special_character_error;
      } else if (isHubMode() && action.specialCharacterError) {
        errorMessage = CMS.placement_test_session_name_special_character_error;
      } else if (action.apiError) {
        errorMessage = CMS.unable_to_check_placement_test_session_name;
      }

      return {
        ...state,
        placementTestSessionNameValidationPending: false,
        placementTestSessionNameValidationIsInvalid: false || action.invalidPlacementTestSessionName,
        placementTestSessionNameValidationIsWarning: action.exists || action.apiError,
        placementTestSessionNameValidationIsValid:
          !action.exists && !action.apiError && !action.invalidPlacementTestSessionName,
        placementTestSessionNameValidationMessage: errorMessage,
        placementTestSessionNameIsTouched: true
      };
    }

    case VALIDATE_PLACEMENT_TEST_SESSION_NAME_ON_BLUR: {
      const { emptyInput } = action;
      return {
        ...state,
        placementTestSessionNameValidationPending: false,
        placementTestSessionNameValidationIsInvalid: emptyInput,
        placementTestSessionNameValidationIsValid: !emptyInput,
        placementTestSessionNameValidationMessage: emptyInput ? CMS.placement_test_session_name_length_error : null
      };
    }

    // After Placement test session has been created:
    case SHOW_CONFIRMATION:
      return {
        ...state,
        formState: formStates.CONFIRMATION,
        placementTestSessionId: action.placementTestSessionId,
        failedUserIds: action.failedUserIds
      };

    case SET_PLACEMENT_TEST:
      return {
        ...state,
        placementTest: { ...state.placementTest, ...action.placementTest }
      };

    case SET_SELECTED_LICENSES:
      return {
        ...state,
        placementTest: { ...state.placementTest, allocatedCredits: action.allocatedCredits }
      };
    // save to draft
    case SET_EDITED_PLACEMENT_TEST:
      return {
        ...state,
        editedPlacementTest: {
          ...state.editedPlacementTest,
          ...action.editedPlacementTest
        }
      };

    case CONTINUE_EDITED_PLACEMENT_TEST:
      return {
        ...state,
        placementTestSessionNameValue: action.payload.placementTestSessionNameValue,
        placementTestSessionId: action.payload.placementTestSessionId,
        placementTestSessionDateCreated: action.payload.placementTestSessionDateCreated,
        joiningCode: action.payload.joiningCode,
        placementTest: action.payload.placementTest
      };

    case SET_ORG_TEST_CREDITS:
      return {
        ...state,
        numberOfLicenses: action.payload.orgTestCredits
      };

    case GET_WIZARD_TEST_CREDITS_SUCCESS:
      return {
        ...state,
        numberOfLicenses: action.payload.testCredits
      };
    case GET_WIZARD_TEST_CREDITS_FAILURE:
      return {
        ...state,
        numberOfLicenses: 0
      };
    case SET_PLACEMENT_TEST_PRODUCT_CHANGED:
      return {
        ...state,
        placementTestProductChanged: true
      };

    // close form
    case CLOSE_FORM:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

// Name Actions
export const setPlacementTestSessionName = placementTestSessionName => ({
  type: SET_PLACEMENT_TEST_SESSION_NAME,
  placementTestSessionName
});

export const placementTestSessionNameOnBlur = () => ({
  type: PLACEMENT_TEST_SESSION_NAME_ON_BLUR
});

export const setPlacementTestSessionNameValidated = ({
  exists,
  apiError,
  invalidPlacementTestSessionName,
  specialCharacterError
}) => ({
  type: VALIDATED_PLACEMENT_TEST_SESSION_NAME,
  exists,
  apiError,
  invalidPlacementTestSessionName,
  specialCharacterError
});

export const setPlacementTestSessionNameOnBlurValidation = emptyInput => ({
  type: VALIDATE_PLACEMENT_TEST_SESSION_NAME_ON_BLUR,
  emptyInput
});

// Form submission and response
export const createPlacementTestSession = () => ({
  type: CREATE_PLACEMENT_TEST_SESSION
});

export const showConfirmation = (createdClassroomId, failedUserIds) => ({
  type: SHOW_CONFIRMATION,
  createdClassroomId,
  failedUserIds
});

export const setPreselectedTeacher = userId => ({
  type: SET_PRESELECTED_TEACHER,
  userId
});

export const clearPreselectedTeacher = () => ({
  type: CLEAR_PRESELECTED_TEACHER
});

export const setPlacementTest = placementTest => ({
  type: SET_PLACEMENT_TEST,
  placementTest
});

// save draft test
export const setEditedPlacementTest = editedPlacementTest => ({
  type: SET_EDITED_PLACEMENT_TEST,
  editedPlacementTest
});

// edit placement test
export const continueEditedPlacementTest = payload => ({
  type: CONTINUE_EDITED_PLACEMENT_TEST,
  payload
});

// Reset action
export const closeForm = () => ({
  type: CLOSE_FORM
});

export const setOrgTestCredits = payload => ({
  type: SET_ORG_TEST_CREDITS,
  payload
});

export const getWizardTestCreditsSuccess = payload => ({
  type: GET_WIZARD_TEST_CREDITS_SUCCESS,
  payload
});
export const getWizardTestCreditsRequest = payload => ({
  type: GET_WIZARD_TEST_CREDITS_REQUEST,
  payload
});
export const getWizardTestCreditsFailure = payload => ({
  type: GET_WIZARD_TEST_CREDITS_FAILURE,
  payload
});
export const setPlacementTestProductChanged = () => ({
  type: SET_PLACEMENT_TEST_PRODUCT_CHANGED
});
