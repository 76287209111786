import { put } from 'redux-saga/effects';
import {
  getPlacementTestSessionRequestError,
  getPlacementTestSessionRequestSuccess
} from '../../../../actions/placementTestOnboardingWizard';
import getPlacementTestRequest from '../../../apiCalls/getPlacementTest';
import { setOrgTestCredits, setPlacementTest } from '../../../../reducers/placementTestSessionCreate';

function* getPlacementTest({ orgId, sessionId }) {
  const response = yield getPlacementTestRequest(orgId, sessionId);
  if (response.status === 'success') {
    yield put(setPlacementTest({ ...response.data.configurations.CONFIGURATION_PLACEMENT_TEST }));
    yield put(setOrgTestCredits({ orgTestCredits: response.data.orgTestCredits }));
    return yield put(
      getPlacementTestSessionRequestSuccess({
        placementTest: response.data.configurations.CONFIGURATION_PLACEMENT_TEST,
        className: response.data.classGroup.className,
        joiningCode: {
          code: response.data.code,
          enabled: true
        }
      })
    );
  }
  return yield put(getPlacementTestSessionRequestError(response.error));
}

export default getPlacementTest;
